<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>分类列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <!--            <div v-show="issearch" class="page-content-search">-->
            <!--                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">-->
            <!--                    <el-row>-->
            <!--                        <el-col :span="6">-->
            <!--                            <el-form-item label="标签名称">-->
            <!--                                <el-input v-model="search.tag_s"></el-input>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <!--                    </el-row>-->
            <!--                    <el-row class="page-content-search-button">-->
            <!--                        <el-col :span="24">-->
            <!--                            <el-form-item>-->
            <!--                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索-->
            <!--                                </el-button>-->
            <!--                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <!--                    </el-row>-->
            <!--                </el-form>-->
            <!--            </div>-->
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <!--                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"-->
                        <!--                                   @click="issearch = !issearch">隐藏搜索-->
                        <!--                        </el-button>-->
                        <!--                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"-->
                        <!--                                   @click="issearch = !issearch">显示搜索-->
                        <!--                        </el-button>-->
                        <el-button v-if="is_auth('weapphome.classification.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="tosee()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="title"
                        label="分类名称">
                    <template slot-scope="scope">
                        <span>{{ scope.row.title }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="icon"
                        label="icon"
                        width="80">
                    <template slot-scope="scope">
                        <viewer>
                            <el-avatar shape="square" :size="22" :src="scope.row.icon"></el-avatar>
                        </viewer>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="tag_s"
                        label="分类">
                    <template slot-scope="scope">
                        <el-tag
                                :key="item.index"
                                v-for="item in scope.row.tag_s"
                                class="form-tag_colour-tag"
                                effect="dark"
                                size="mini"
                                :color="item.tag_colour">
                            {{ item.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="sort"
                        label="排序">
                </el-table-column>
                <el-table-column
                        prop="we_analyse"
                        label="we分析">
                </el-table-column>
                <el-table-column
                        prop="describe"
                        label="描述">
                    <template slot-scope="scope">
                        <span>{{ scope.row.describe }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="添加时间">
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="修改时间">
                </el-table-column>

                <el-table-column
                        fixed="right"
                        label="操作"
                        width="250">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('weapphome.classification.getinfo')"
                                @click="tosee(scope.row.id)"
                                size="mini">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('weapphome.classification.isenable') && scope.row.state===2 "
                                @click="operation_tip(scope.row.id,scope.row.title,'isenable')"
                                type="primary" size="mini">启用
                        </el-button>
                        <el-button
                                v-if="is_auth('weapphome.classification.isdisable') && scope.row.state===1 "
                                @click="operation_tip(scope.row.id,scope.row.title,'isdisable')"
                                type="danger" size="mini">禁用
                        </el-button>
                        <el-button
                                v-if="is_auth('weapphome.classification.isdel')"
                                @click="operation_tip (scope.row.id,scope.row.title,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '分类列表',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                tag_s: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "weapphome.classification.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(id = '', title = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + title + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + title + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + title + '】？'
                options = {type: 'error'}
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(id, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(id = '', operation = '') {
            let postdata = {
                api_name: "weapphome.classification." + operation,
                token: this.Tool.get_l_cache('token'),
                id: id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(id = '') {
            if (id === '') {
                this.$router.push({path: '/weapphome/classification/add'})
            } else {
                this.$router.push({path: '/weapphome/classification/add', query: {id: id}})
            }
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
